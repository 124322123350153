import { __awaiter, __generator } from "tslib";
/**************************************
 * Created By LinK On 2019/11/25 16:32.
 **************************************/
import key from './key';
export default [
    {
        point: '@@components',
        namespace: key.ID,
        onfirst: function (app) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, app.store.dynamic(key.ID, function () { return import('./aliPay.store'); }).load(key.ID)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); },
        onload: function () { return [{ key: 'AliPayView', component: function () { return import('./ali-pay-view'); } }]; }
    }
];
